import { createAction, props } from '@ngrx/store';

import { ResetPassword } from '@models/password';

export const resetPassword = createAction('[ResetPassword/API] Reset Password', props<{ resetPasswordData: ResetPassword }>());
export const resetPasswordSuccess = createAction('[ResetPassword/API] Reset Password Success', props<{ message: string }>());
export const resetPasswordFailure = createAction('[ResetPassword/API] Reset Password Failure', props<{ error: string }>());

export const setCurrentStep = createAction('[ResetPassword] Set Current Step',
  props<{ currentStep: string }>());
