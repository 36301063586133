import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/reset-password.actions';

import { PAGE_STEPS } from '../../pages/reset-password/constants/page-steps';

export const resetPasswordFeatureKey = 'resetPasswordData';

export interface ResetPasswordState {
  message: string;
  currentStep: string;
  pending: boolean;
  error: string;
}

export const initialState: ResetPasswordState = {
  message: null,
  currentStep: PAGE_STEPS.resetPassword,
  pending: false,
  error: null,
};

export const resetPasswordReducer = createReducer(
  initialState,

  on(actions.resetPassword, state => ({ ...state, pending: true })),
  on(actions.resetPasswordSuccess, (state, { message }) => ({ ...state, message, currentStep: PAGE_STEPS.success, pending: false })),
  on(actions.resetPasswordFailure, (state, { error }) => ({ ...state, error, pending: false })),

  on(actions.setCurrentStep, (state, { currentStep }) => ({ ...state, currentStep })),
);
